import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const ProductCard = (props) => {
  return (
    <>
      <Link
        to={"/product/" + props.productId}
        className="single_product_card_container"
      >
        <div className="single_product_card">
          <img src={props.imageLink} className="card_img" />
          <hr />
          <h6 class="card_title">{props.title}</h6>
        </div>
      </Link>
    </>
  );
};

export default ProductCard;

import React, { useEffect, useState } from "react";
import axios from "axios";

import "./styles.css";

import { Slide, AttentionSeeker } from "react-awesome-reveal";

import heroLogo from "../../assets/img/logo/logo.png";
import ppe_image from "../../assets/img/hero/ppe.jpg";
import bms_image from "../../assets/img/hero/bms.jpg";
import fire_image from "../../assets/img/hero/fire.jpg";
import gas_image from "../../assets/img/hero/gas.jpg";
import HeroProductCard from "./HeroProductCard";

const Hero = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {}, []);

  const fetchCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/products/categories?consumer_key=${process.env.REACT_APP_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_CONSUMER_SECRET}&parent=0`
      )
      .then((response) => {
        console.log(response.data);
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <img src={heroLogo} alt="hero logo" className="hero_logo" />

      <div className="hero_section">
        <Slide direction="down" duration={1500}>
          <h1 className="company_name">Zenith Safety Solution</h1>
        </Slide>

        <Slide direction="right" duration={1500}>
          <h1 className="motto1">Respecting the Life of People</h1>
        </Slide>

        <br />
        <Slide direction="left" duration={1500}>
          <h1 className="motto2">One Stop Safety Solution</h1>
        </Slide>

        <div className="hero_product container">
          <div className="row">
            <div className="col-md-3 col-6">
              <Slide direction="up" duration={1500}>
                <HeroProductCard
                  img={ppe_image}
                  title="Personal Protective Equipment"
                  id="15"
                />
              </Slide>
            </div>
            <div className="col-md-3 col-6">
              <Slide direction="up" duration={1500}>
                <HeroProductCard
                  img={bms_image}
                  title="Building Managentment System"
                  id="hash"
                />
              </Slide>
            </div>
            <div className="col-md-3 col-6">
              <Slide direction="up" duration={1500}>
                <HeroProductCard
                  img={fire_image}
                  title="Fire Detection System"
                  id="27"
                />
              </Slide>
            </div>
            <div className="col-md-3 col-6">
              <Slide direction="up" duration={1500}>
                <HeroProductCard
                  img={gas_image}
                  title="Gas Detection System"
                  id="31"
                />
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;

import React from "react";

const SingleClient = (props) => {
  return (
    <div className="client_card">
      <img src={props.imageLink} alt="Client Logo" className="client_logo" />
    </div>
  );
};

export default SingleClient;
